var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "pa-0 regusto-widget" }, [
    _c(
      "div",
      { staticClass: "px-2 pt-2" },
      [
        !_vm.hideTitle
          ? _c("ebsn-meta", {
              staticClass: "text-uppercase",
              attrs: {
                target: _vm.category,
                path: "category_info.TITLE",
                tag: "h4"
              }
            })
          : _vm._e(),
        _c("ebsn-meta", {
          staticClass: "description",
          attrs: {
            target: _vm.category,
            path: "category_info.DESCRIPTION",
            tag: "div"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "iframe-container" }, [
      _c("iframe", {
        ref: "regustoIframe",
        attrs: {
          width: "100%",
          src: `https://app.regusto.eu/widget/${_vm.TOKEN}`,
          frameborder: "0",
          scrolling: "no"
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }